import React from 'react'
import Helmet from 'react-helmet'
import PageLink from '@/link.js'
import { Arrow } from '../svgs.js'
import vsbl from 'vsbl'
import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

export default class InlineVideo extends React.PureComponent {

  constructor (props) {
    super(props)
    this.video = React.createRef()
    this.vs = null
  }

  componentDidMount() {
    const Plyr = require('plyr')
    let controls = ['play-large']
    const video = this.video.current
    if (this.props.content.showControls === 'yes') {
      controls = ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
    }

    this.player = new Plyr(video, {
      autopause: false,
      muted: false,
      vimeo: {
        controls: false,
        transparent: true,
        autoplay: false,
        muted: false,
        h: this.props.content.videoHash
      },
      loop: { active: true },
      quality: { default: 576 },
      controls: controls
    })

    this.player.on('ready', event => {
      const player = event.detail.plyr;

      if (this.props.content.loop == true && this.props.content.autoplay == true || this.props.content.autoplay == true && this.props.content.loop == null ) {
        setTimeout(() => {
        this.player.loop = true;
        this.player.muted = true;
          this.player.play();
        }, 200)
      }
      else {
        this.player.muted = false;
        this.player.loop = false;
      }
    });

    this.vs = vsbl(video)(() => {
      if (this.props.content.autoplay) {
        this.player.decreaseVolume(1)
        this.player.decreaseVolume(1)
        this.player.decreaseVolume(1)
        this.player.decreaseVolume(1)
        this.player.play()
      }

    })
    this.vs.update()
  }

  componentWillUnmount() {
    this.player && this.player.destroy()
    this.vs && this.vs.destroy()
  }

  render() {
    const {
      title,
      link,
      direction,
      description
    } = this.props.content
    return (
      <div className={cx('f jcs fw ais container--xl ma x p1 rel image__text video', this.props.content.direction)}>
        <Helmet>
        </Helmet>
        <div className='image__text-slideshow video'>
          <div
            ref={this.video}
            data-plyr-provider="vimeo"
            data-plyr='{ "volume": 1 }'
            data-plyr-embed-id={this.props.content.videoId}
            preload="true"
          />
        </div>
        <div className='image__text-text video'>
        <h5 className={cx('caps mono', {'hide': !title})}>{title}</h5>
          <p className='mb1'><BlockContent blocks={description} /></p>
          {link && (<PageLink to={link._ref} className='f link__arrow jcs aic'><span className='link-underline'>Read More</span> </PageLink>)}
        </div>
      </div>
    )
  }
}

import React from 'react'
import cx from 'classnames'
import Image from '@/image.js'
import VideoModule from '@/modules/videoModule.js'

const DoubleImage = ({ content }) => {
  const { images } = content
  const handleMedia = (items) => {
    return items.map(item => {
      switch (item._type) {
        case 'image':
          return (
            <div key={item._key} className='x'>
              <Image timeOut={400} clasName='x' source={item.image} imageId={item.imageId} alt='' />
            </div>
          )
        case 'videoModule':
          return (<VideoModule key={item._key} content={item} />)
        default:
          return (<span />)
      }
    })
  }
  return (
    <div className='container--xl simple-xl ma x p05'>
      <div className={cx('image__double f jcb fw', {
        'col-3': images.length === 3,
        'col-4': images.length === 4
      })}>
        {handleMedia(images)}
      </div>
    </div>
  )
}

export default DoubleImage

import React from 'react'

import { Arrow } from '../svgs.js'
import cx from 'classnames'
import Image from '@/image.js'

class MiscCarousel extends React.Component {
  constructor () {
    super()
    this.state = {
      activeSlide: 1
    }
    this.slideshow = React.createRef()
    this.rightArrow = React.createRef()
    this.leftArrow = React.createRef()
  }
  componentDidMount () {
    
    console.log('props', this.props)
    const Flickity = require('flickity')
    const slideshow = this.slideshow.current
    const flky = new Flickity(slideshow, {
      contain: true,
      wrapAround: true,
      pageDots: false,
      bgLazyLoad: 2,
      cellAlign: 'center',
      autoPlay: this.props.content.autoplay,
      prevNextButtons: false
    })
    
  
    const left = this.leftArrow.current
    const right = this.rightArrow.current
  
    left.addEventListener('click', () => flky.previous())
    right.addEventListener('click', () => flky.next())
  
    flky.on('select', (index) => this.setState({ activeSlide: index + 1 }))
  
    setTimeout(() => {
      flky.resize()
    }, 100)
  }
  render () {
    const {
      title,
      titleBottom,
      images
    } = this.props.content
    return (
      <div className='container--xl ma simple-xl px15'>
        {title && (<div className='py1 misc__header top'><h1 className='container--h x'>{title}</h1></div>)}
        <div className={cx('x cw carousel__wrapper rel')}>
          <div ref={this.leftArrow} className='slide__controls cw slide__controls-left abs z1 left'>
            <Arrow />
          </div>
          <div ref={this.rightArrow} className='slide__controls cw slide__controls-right abs z1 right'>
            <Arrow />
          </div>
          <div ref={this.slideshow} className='carousel'>
            {images.map(slide => (
              <div className='x carousel-slide' key={slide._key}>
                <Image timeOut={800} className='x y' alt='' imageId={slide.imageId} />
              </div>
            ))}
          </div>
        </div>
        {titleBottom && (<div className='py1 misc__header bottom'><h1 className='container--h x'>{titleBottom}</h1></div>)}
      </div>
    )
  }
}

export default MiscCarousel

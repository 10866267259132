import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

export default class PortfolioCaseStudyInfo extends React.Component {
  render () {
    const {
      description,
      credits,
      categories,
      heading1,
      heading2,
      heading3
    } = this.props.content
    return (

      <div className='container--xl simple-xl project__credit-wrapper f jcb ais fw ma x px1 py05' ref={this.description}>
          <div className='project__credit x'>
            <h5 className='caps mt0 mono  pt0'>{heading1 ? heading1 : 'Description'}</h5>
            <div className=''>
              <p>
              <BlockContent blocks={description} />
              </p>
            </div>
          </div>
          {this.props.content.credits &&
          <div className='project__credit x'>
            <h5 className='caps mt0 mono  pt0'>{heading2 ? heading2 : 'Credits'}</h5>
            <div className=''>
              <p>
              <BlockContent blocks={credits} />
              </p>
            </div>
          </div>
          }
          {this.props.content.categories &&
          <div className='project__credit x'>
            <h5 className='caps mt0 mono  pt0'>{heading3 ? heading3 : 'Categories'}</h5>
            <div className=''>
              <p>
              <BlockContent blocks={categories} />
              </p>
            </div>
          </div>
          }
      </div>
    )
  }
}

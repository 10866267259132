import React from 'react'

const ClientList = ({ content }) => (
  <div className='container--xl ma p1 simple-xl'>
    <div className='clients__wrapper'>
      <h5 className='caps mt0 mono'>{content.title}</h5>
      <div className='clients'>
        {content.clients && content.clients.map((client, i) => (
          <div key={i} className='p '>{client}</div>
        ))}
      </div>
    </div>
  </div>
)

export default ClientList
import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

export default class ProjectTextModule extends React.Component {
render () {
    const {
	  heading,
      copy,
      rules
    } = this.props.content
    return (
      <div className={cx('ma x jcb aic fw mt2 portfolioCaseStudyIntroBlock project', {
        'project__text-block noBorders': rules === 'noRule',
        'project__text-block borders': rules === 'rule'
      })}>
      {heading && <h1 className='light mb0 mt0 pt05 pb0'>{heading}</h1>}
      <div class="project__text f jcb aibs fw standard"> 
	  	<div class="x y">
        <BlockContent blocks={copy} />
        </div>
        </div>
      </div>
    )
  }
}

import React from 'react'
import Image from '../image.js'

const TeamMembers = ({ content }) => (
  <div className='container--xl simple-xl team ma f fw jcb ais p1 mt05'>
    {content.members && content.members.map(member => (
      <div className='team__single x mt05 mb1' key={member._id}>
        <Image timeOut={400} className='x' alt={member.name} imageId={member.imageId} />
        <h5 className='caps mt1 mb0 block mono'>{member.subtitle}</h5>
        <h4 className='p mt05 mb0 p0'>{member.name}</h4>
      </div>
    ))}
    <div className='x' />
    <div className='x' />
    <div className='x' />
    <div className='x' />
  </div>
)

export default TeamMembers

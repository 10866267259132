import React from 'react'
import cx from 'classnames'
import PageLink from '@/link.js'
import { Arrow, ArrowExternal } from '@/svgs.js'

export default class PartnerModule extends React.Component {

  constructor() {
    super()
    this.state = {
      slideshow: null
    }
    this.slideshow = React.createRef()
    this.rightArrow = React.createRef()
    this.leftArrow = React.createRef()
  }
  componentDidMount() {
    const Flickity = require('flickity')
    const slideshow = this.slideshow.current
    const flky = new Flickity(slideshow, {
      contain: true,
      wrapAround: true,
      pageDots: false,
      bgLazyLoad: 2,
      cellAlign: 'center',
      prevNextButtons: false
    })

    const left = this.leftArrow.current
    const right = this.rightArrow.current

    left.addEventListener('click', () => flky.previous())
    right.addEventListener('click', () => flky.next())
    this.setState({
      slideshow: flky
    })
    setTimeout(() => {
      flky.resize()
    }, 100)
  }
  componentDidUpdate () {
    // console.log('more?', this.state)
    setTimeout(() => {
      this.state.slideshow.resize()
    }, 200)
  }

  render () {
    const {
      images,
      title,
      url,
      description
    } = this.props.content
    return (
      <div className='f jcs fw ais container--xl ma x p1 rel image__text partnerModuleWrapper'>

        <div className={cx('x cw carousel__wrapper rel')}>
          <div ref={this.leftArrow} className='slide__controls cw slide__controls-left abs z1 left'>
            <Arrow />
          </div>
          <div ref={this.rightArrow} className='slide__controls cw slide__controls-right abs z1 right'>
            <Arrow />
          </div>
          <div ref={this.slideshow} className='carousel'>
          {images.map((image) => { return (
          <div className='x carousel-slide'>
            <div><a href={url} target='_blank'><img src={image.image} /></a></div>
          </div>
          )})}
        </div>
        </div>

        <div className='image__text-text'>
          <p className='mb1'>{description}
          <a href={url} target='_blank' className='p contact__link contact__small' rel='noopener noreferrer'>
            <span>
              <ArrowExternal className='inline' />
            </span>
          </a></p>
        </div>
      </div>
    )
  }
}

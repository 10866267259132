import React from 'react'

import PageLink from '@/link.js'
import Slideshow from './slideshow.js'
import { Arrow } from '@/svgs.js'

export default class ImageText extends React.Component {
  render () {
    const {
      images,
      title,
      link,
      description
    } = this.props.content
    return (
      <div className='f jcs fw ais container--xl ma x p1 rel image__text'>
        <Slideshow images={images} className='image__text-slideshow' />
        <div className='image__text-text'>
          <h5 className='caps mono'>{title}</h5>
          <p className='mb1'>{description}</p>
          {link && (<PageLink to={link._ref} className='f link__arrow jcs aic'><span className='link-underline'>Read More</span> <Arrow className='ml05' /></PageLink>)}
        </div>
      </div>
    )
  }
}

import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import Slideshow from './slideshow.js'

class PrinciplesAndCapabilities extends React.Component {
  render () {
    const {
      capabilities,
      images,
      capabilitiesTitle,
      principles,
      principleTitle
    } = this.props.content
    return (
      <div className='container--xl simple-xl ma x p1 principles'>
        <div className='f jcb principles__wrapper fw'>
          <div className='y x'>
            <h5 className='mono caps'>{principleTitle}</h5>
            <BlockContent blocks={principles} />
          </div>
          <div className='f jcb principles__flex'>
            <div>
              <h5 className='mono caps'>{capabilitiesTitle}</h5>
              <BlockContent blocks={capabilities} />
            </div>
            <Slideshow images={images} />
          </div>
        </div>
      </div>
    )
  }
}

export default PrinciplesAndCapabilities

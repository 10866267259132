import React from 'react'

import { FooterLogo, FooterLogoTwo, FooterLogoThree, FooterLogoFour, FooterLogoFive } from '@/svgs.js'

class FooterRender extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      updated: false,
      logo: Math.floor(Math.random() * 4) + 1
    }
  }
  componentDidMount () {
    setTimeout(() => {
      this.setState({
        logo: Math.floor(Math.random() * this.props.logos.length) + 1
      })
    }, 300)
  }
  randomLogo() {
    const { logos } = this.props
    switch (this.state.logo) {
      case 20:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 20 ? logos[19] : logos[0] }} />
      case 19:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 19 ? logos[18] : logos[0] }} />
      case 18:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 18 ? logos[17] : logos[0] }} />
      case 17:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 17 ? logos[16] : logos[0] }} />
      case 16:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 16 ? logos[15] : logos[0] }} />
      case 15:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 15 ? logos[14] : logos[0] }} />
      case 14:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 14 ? logos[13] : logos[0] }} />
      case 13:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 13 ? logos[12] : logos[0] }} />
      case 12:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 12 ? logos[11] : logos[0] }} />
      case 11:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 11 ? logos[10] : logos[0] }} />
      case 10:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 10 ? logos[9] : logos[0] }} />
      case 9:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 9 ? logos[8] : logos[0] }} />
      case 8:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 8 ? logos[7] : logos[0] }} />
      case 7:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 7 ? logos[6] : logos[0] }} />
      case 6:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 6 ? logos[5] : logos[0] }} />
      case 5:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 5 ? logos[4] : logos[0] }} />
      case 4:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 4 ? logos[3] : logos[0] }} />
      case 3:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 3 ? logos[2] : logos[0] }} />
      case 2:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos.length >= 2 ? logos[1] : logos[0] }} />
      default:
        return <div className="al" dangerouslySetInnerHTML={{__html: logos[0] }} />
    }

  }

  render () {
    return (
      <div>
        {this.randomLogo()}
      </div>
    )
  }
}

export default FooterRender

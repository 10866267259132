import React from 'react'
import Image from '@/image.js'
import cx from 'classnames'

import { renderTag } from '@/utls.js'

const InlineImage = ({ content, index, categoryLength }) => {
  return (
    <React.Fragment>
      {renderTag(content, index, categoryLength)}
      <div className={cx('container--xl simple-xl ma x px1 py05', content.category && `cat-${content.category.replace(/\s+/g, '-').toLowerCase()}`)}>
        <Image timeout={400} className='x' imageId={content.imageId} source={content.image} alt={content.altText} />
      </div>
    </React.Fragment>
  )
}

export default InlineImage

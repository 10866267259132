import React from 'react'
import SingleWork from './singleWork.js'

const CaseStudies = ({ content }) => {
  return (
    <div className='f fw jcb aic container--xl ma x p1  case__studies'>
      {content.study.map(single => (
        <SingleWork data={single} key={single._id} />
      ))}
    </div>
  )
}

export default CaseStudies

import React from 'react'

// import Hero from './global/hero.js'

// Article Specific Modules
import CaseStudies from './modules/caseStudies.js'
import VideoText from './modules/videoText.js'
import ImageText from './modules/imageText.js'
import PartnerModuleTitle from './modules/partnerModuleTitle.js'
import PartnerModule from './modules/partnerModule.js'
import ClientList from './modules/clientList.js'
import MiscCarousel from './modules/miscCarousel.js'
import TeamMembers from './modules/teamMembers.js'
import InlineImage from './modules/inlineImage.js'
import DoubleImage from './modules/doubleImage.js'
import ProjectImageText from './modules/projectImageText.js'
import VideoModule from './modules/videoModule.js'
import PrinciplesAndCapabilities from './modules/principlesAndCapabilities.js'
import PortfolioCaseStudyIntroBlock from './modules/portfolioCaseStudyIntroBlock.js'
import PortfolioCaseStudyInfo from './modules/portfolioCaseStudyInfo.js'
import ProjectTextModule from './modules/projectTextModule.js'

class Modules extends React.Component {
  render() {
    const { module, type, index, categoryLength } = this.props
    switch (type) {
      case 'clientList':
        return <ClientList index={index} categoryLength={categoryLength} content={module} />
      case 'caseStudies':
        return <CaseStudies index={index} categoryLength={categoryLength} content={module} />
      case 'teamMembers':
        return <TeamMembers index={index} categoryLength={categoryLength} content={module} />
      case 'doubleImage':
        return <DoubleImage index={index} categoryLength={categoryLength} content={module} />
      case 'miscCarousel':
        return <MiscCarousel index={index} categoryLength={categoryLength} content={module} />
      case 'inlineImage':
        return <InlineImage index={index} categoryLength={categoryLength} content={module} />
      case 'projectImageText':
        return <ProjectImageText index={index} categoryLength={categoryLength} content={module} />
      case 'portfolioCaseStudyIntroBlock':
        return <PortfolioCaseStudyIntroBlock index={index} categoryLength={categoryLength} content={module} />
      case 'portfolioCaseStudyInfo':
        return <PortfolioCaseStudyInfo index={index} categoryLength={categoryLength} content={module} />
      case 'projectTextModule':
        return <ProjectTextModule index={index} categoryLength={categoryLength} content={module} />
      case 'imageText':
        return <ImageText index={index} categoryLength={categoryLength} content={module} />
      case 'videoText':
        return <VideoText index={index} categoryLength={categoryLength} content={module} />
      case 'partnerModuleTitle':
        return <PartnerModuleTitle index={index} categoryLength={categoryLength} content={module} />
      case 'partnerModule':
        return <PartnerModule index={index} categoryLength={categoryLength} content={module} />
      case 'principlesAndCapabilities':
        return <PrinciplesAndCapabilities index={index} categoryLength={categoryLength} content={module} />
      case 'videoModule':
        return <VideoModule index={index} categoryLength={categoryLength} content={module} />
      default:
        return (<div className='h1 p1'>{type}</div>)
    }
  }
}

export default Modules

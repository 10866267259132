import React from 'react'
import Image from '@/image.js'
import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import { renderTag } from '@/utls.js'

const ProjectImageText = ({ content, index, categoryLength }) => {

  return (
    <React.Fragment>
      {renderTag(content, index, categoryLength)}
      <div className={cx('container--xl simple-xl ma x p1', content.category && `cat-${content.category.replace(/\s+/g, '-').toLowerCase()}`)}>
        <div className={cx('project__text f jcb ais fw', content.direction)}>
          <div className='x y'>
            <Image timeout={400} className='x' imageId={content.imageId} source={content.image} alt={content.altText} />
          </div>
          <div className={cx('x project__text-block px15', content.borderCheck)}>
            <BlockContent blocks={content.description} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProjectImageText

import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Modal from 'react-modal'

import BlockContent from '@sanity/block-content-to-react'

import { ArrowExternal, Close } from '@/svgs.js'
import PageLink from '@/link.js'
import { linkSerializer } from '@/utls.js'
import FooterRender from '@/misc/footerLogo.js'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    width                 : '90vw',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

const Footer = () => {
  const query = useStaticQuery(
    graphql`
      {
        sanityConfig {
          id
          internal {
            type
            content
            contentDigest
            owner
          }
        }
      }
    `)

  const data = JSON.parse(query.sanityConfig.internal.content)
  const [modalIsOpen, setIsOpen] = useState(false)
  const video = React.createRef()
  let player = {}
  const openModal = (e) => {
    e.preventDefault()
    setIsOpen(true)
  }
  const afterOpenModal = () => {
    // references are now sync'd and can be accessed. 
    const Plyr = require('plyr')
    const videoRef = video.current
    const controls = ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
    player = new Plyr(videoRef, {
      autopause: true,
      muted: true,
      vimeo: {
        controls: false,
        autoplay: false,
        muted: true
      },
      loop: { active: true },
      qaulity: { default: 576 },
      controls: controls
    })
    setTimeout(() => {
            player.play();
          }, 200)
  }
  const closeModal = () => {
    setIsOpen(false)
  }
  useEffect(() => {

    // return () => player.destroy()
  })



  const {
    reelVideoId,
    footerLoogs
  } = data
  return (
    <footer className='footer'>
      <div className='f jcb p1 footer__inner rel container--xl ma fw x'>
        <div className='footer__inner-main x'>
          <div className='footer__push footer__logo al'>
            <FooterRender logos={footerLoogs} />
          </div>
          <div className=' footer__description'>
            <BlockContent blocks={data.footerDescription} />
          </div>
        </div>
        <div className='footer__inner-index mb2'>
          <h5 className='p0 m0 footer__push mono caps '>Index</h5>
          <PageLink className='block p mt05' to='/work'>Work</PageLink>
          <PageLink className='block p' to='/about'>About</PageLink>
          <PageLink className='block p' to='/contact'>Contact</PageLink>
          <a href='https://instagram.com/grandarmy' target='_blank' className='block p contact__link contact__small' rel='noopener noreferrer'>
            <span>Instagram
              <ArrowExternal className='inline' />
            </span>
          </a>
          {reelVideoId && (
            <a href='#modal' onClick={openModal} className='block p'>Reel</a>
          )}
          <PageLink className='block p' to='/portfolio/photo-studio'>Photo Studio</PageLink>
          <PageLink className='block p' to='/pasteboard'>Pasteboard</PageLink>
          {/* <PageLink className='block p' to='/shop'>Shop</PageLink> */}
          <p id="copyrightMobile">{data.mobileFooterYear}</p>
        </div>

        <div className='footer__inner-contact '>
          <h5 className='p0 m0 footer__push mono caps '>Contact</h5>
          <BlockContent blocks={data.contactInformation} serializers={linkSerializer} />
        </div>
        <div className='footer__inner-visit '>
          <h5 className='p0 m0 footer__push mono caps '>Visit</h5>
          <BlockContent blocks={data.footerAddress} />
        </div>
      </div>
      <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button className='cw' onClick={closeModal}><Close /></button>
          <div
            ref={video}
            data-plyr-provider="vimeo"
            data-plyr='{ "volume": 0 }'
            data-plyr-embed-id={reelVideoId}
            preload="true"
          />
        </Modal>
    </footer>
  )
}

export default Footer

import React from 'react'
import cx from 'classnames'

import Image from '@/image.js'
import { Arrow } from '@/svgs.js'

class Slideshow extends React.Component {
  constructor() {
    super()
    this.state = {
      slideshow: null
    }
    this.slideshow = React.createRef()
    this.rightArrow = React.createRef()
    this.leftArrow = React.createRef()
  }
  componentDidMount() {
    const Flickity = require('flickity')
    const slideshow = this.slideshow.current
    const flky = new Flickity(slideshow, {
      contain: true,
      wrapAround: true,
      pageDots: false,
      bgLazyLoad: 2,
      cellAlign: 'center',
      prevNextButtons: false
    })

    const left = this.leftArrow.current
    const right = this.rightArrow.current

    left.addEventListener('click', () => flky.previous())
    right.addEventListener('click', () => flky.next())
    this.setState({
      slideshow: flky
    })
    setTimeout(() => {
      flky.resize()
    }, 100)
  }
  componentDidUpdate () {
    // console.log('more?', this.state)
    setTimeout(() => {
      this.state.slideshow.resize()
    }, 200)
  }
  render () {
    const {
      images,
      className
    } = this.props
    return (
      <div className={cx('x cw carousel__wrapper rel', className)}>
        <div ref={this.leftArrow} className='slide__controls cw slide__controls-left abs z1 left'>
          <Arrow />
        </div>
        <div ref={this.rightArrow} className='slide__controls cw slide__controls-right abs z1 right'>
          <Arrow />
        </div>
        <div ref={this.slideshow} className='carousel'>
          {images.map(slide => (
            <div className='x carousel-slide' key={slide._key}>
              <Image timeOut={800} className='x y' alt='' imageId={slide.imageId} />
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Slideshow

import React from 'react'
import Helmet from 'react-helmet'
import vsbl from 'vsbl'

export default class InlineVideo extends React.PureComponent {

  constructor (props) {
    super(props)
    this.video = React.createRef()
    this.vs = null
  }

  componentDidMount() {
    const Plyr = require('plyr')
    let controls = ['play-large']
    const video = this.video.current
    if (this.props.content.showControls === 'yes') {
      controls = ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
    }

    this.player = new Plyr(video, {
      autopause: false,
      muted: true,
      ratio: this.props.content.videoAspect,
      vimeo: {
        controls: false,
        transparent: true,
        autoplay: false,
        muted: false,
        h: this.props.content.videoHash
      },
      loop: { active: true },
      quality: { default: 576 },
      controls: controls
    })

    this.player.on('ready', event => {
      const player = event.detail.plyr;
      if (this.props.content.loop == true && this.props.content.autoplay == true || this.props.content.autoplay == true && this.props.content.loop == null ) {
          setTimeout(() => {
          this.player.loop = true;
          this.player.muted = true;
            this.player.play();
          }, 200)
      }
      else {
        this.player.loop = false;
        this.player.muted = false;
      }
    });

    this.vs = vsbl(video)(() => {
      if (this.props.content.autoplay) {
        this.player.decreaseVolume(1)
        this.player.decreaseVolume(1)
        this.player.decreaseVolume(1)
        this.player.decreaseVolume(1)
        this.player.play()
      }

    })
    this.vs.update()
  }

  componentWillUnmount() {
    this.player && this.player.destroy()
    this.vs && this.vs.destroy()
  }

  render() {
    return (
      <div className='container--xl mxa px1 py05 workVideo'>
        <Helmet>
        </Helmet>
        <div
          ref={this.video}
          data-plyr-provider="vimeo"
          data-plyr='{ "volume": 1 }'
          data-plyr-embed-id={this.props.content.videoId}
          preload="true"
        />
      </div>
    )
  }
}

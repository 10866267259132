import React from 'react'

import Slideshow from './slideshow.js'

export default class PartnerModuleTitle extends React.Component {
  render () {
    const {
      title
    } = this.props.content
    return (
      <div className='f jcs fw ais container--xl ma x p1 rel image__text partnerModuleTitleWrapper'>
        <div className='partnerModuleTitle'>
          <h2>{title}</h2>
        </div>
      </div>
    )
  }
}

import React from 'react'
import cx from 'classnames'
import Modules from './modules.js'
import PageLink from '@/link.js'
import { ArrowExternal } from '@/svgs.js'
import jump from 'jump.js'

export function jumppy (to) {
  jump(`.cat-${to.replace(/\s+/g, '-').toLowerCase()}`, {
    offset: -120
  })

}

export function renderModules (modules, categoryLength) {
  if (modules) {
    let value = 0
    return modules.map((module) => {
      if (module.category) {
        value++
      }
      return <Modules
        key={module._key}
        type={module._type}
        index={value}
        categoryLength={categoryLength}
        module={module} />
    })
  }
}

export function renderTags(modules) {
  if (modules) {
    return modules.map((module) => {
      if (module.category) {
        return (
          <div key={module._key} className={cx('category top left',  module.category.replace(/\s+/g, '-').toLowerCase())}>
            <span className='category__flag caps mono'>{module.category}</span>
          </div>
        )
      }
    })
  }
}

export function renderTag(module, index, categoryLength) {
  if (module.category) {
    const baseLine = categoryLength * 37
    const bottomPixels = (baseLine - (index * 20))
    const topPixels = (98 + (index * 20))
    return (
      <div onClick={() => jumppy(module.category)} style={{ bottom: `${bottomPixels}px`, top: `${topPixels}px` }} key={module._key} className={cx('category js-cats top left', `category-${index}`, module.category.replace(/\s+/g, '-').toLowerCase())}>
        <span className='category__flag caps mono'>{module.category}</span>
      </div>
    )
  }
}

export const linkSerializer = {
  marks: {
    link: props => {
      if (/http/.test(props.mark.href) || /mailto/.test(props.mark.href)) {
        return (
          <a href={props.mark.href} target='_blank' className='contact__link contact__small' rel='noopener noreferrer'>
            <span>{props.children}
              <ArrowExternal className='inline' />
            </span>
          </a>
        )
      } else {
        return (<PageLink to={props.mark.href}>{props.children}</PageLink>)
      }
    }
  }
}

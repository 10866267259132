import React from 'react'
import BlockContent from '@sanity/block-content-to-react'

export default class PortfolioCaseStudyIntroBlock extends React.Component {
  render () {
    const {
      title,
      description
    } = this.props.content
    return (
      <div className='ma x f jcb aic fw mt2 portfolioCaseStudyIntroBlock'>
        <h1 className='light mb0 mt0 pt05 pb0'>
          {title}<br /><span className='italic light'>{description}</span>
        </h1>
      </div>
    )
  }
}

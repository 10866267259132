import React from 'react'
import Image from '../image.js'

import PageLink from '../link.js'

const SingleWork = ({ data }) => (
  <div className='work__item rel my05 x'>
    <PageLink to={`/projects/${data.slug.current}`} className='abs x y block top left z2' />
    <div className='cw x mb1 z1 p1 work__item-text abs z1 top x y f jcs aie'>
      <div className=''>
        <h3 className='m0 p0'>{data.clientName}<br />
        <span className='m0 p0 italic'>{data.title}</span></h3>
      </div>
    </div>
    <Image timeOut={400} width={600} className='x y' alt='' source={data.image} imageId={data.imageId} />
  </div>
)

export default SingleWork
